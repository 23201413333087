.title {
  text-align: left;
}

.insxns {
  text-align: left;
  color: grey;
  display: flex;
  justify-content: space-between;
  margin: 1rem;
}

.itemInfoTable {
  font-size: x-small;
}

th {
  text-align: left;
  font-size: x-large;
}

td {
  padding: 0.7rem;
}

input {
  width: 100%;
  font-size: x-large;
  padding: 0.2rem;
  border: solid lightblue;
}